<template>
  <div class="d-flex justify-content-between align-item-center">
    <div class="position-relative bg-dark h-100 w-100 p-4">
      <div class="mb-3 rounded d-flex flex-wrap row mx-n1">
        <div class="col-6 col-md p-1" v-for="live in lives" :key="live._id">
          <div
            class="video-thumbnail py-2 pl-3 pr-2 text-light d-flex justify-content-between align-items-center rounded"
            :style="`background-color: ${stream.id == live.id ? '#28343E' : ''
              } !important; border: ${stream.id == live.id ? '2px solid #303F4B ' : ''
              }`">
            {{ live.name }}
            <button v-if="stream.id != live.id" @click="initializeLiveStream(live)" type="button"
              class="btn btn-primary px-3 py-2 shadow" style="opacity: 0.8">
              <i class="fas fa-play mr-2" />Play
            </button>
            <div v-else class="bg-danger rounded shadow px-3 py-2 blinking">
              <p class="m-0 ">
                <i class="fas fa-broadcast-tower mr-2" />Live
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="video-frame d-flex justify-content-center align-items-center"
        :style="`background-image: ${stream.id && 'none !important'}`">
        <div class="position-absolute h-100 w-100 bg-img p-3 d-flex justify-content-center align-items-center"
          :style="stream.id ? 'z-index: 0' : 'z-index: 1'">
          <span class="p-3 text-white bg-semitrans-dark border border-dark rounded" v-if="!stream.name">
            Select camera to start stream
          </span>
        </div>
        <div class="embed-responsive embed-responsive-16by9">
          <video id="liveStream" muted playsinline class="videoPlayer video-js embed-responsive-item"></video>
        </div>
        <div class="position-absolute" v-if="stream.isInitialized && isLoadingStream">
          <div class="spinner-border"></div>
          <p class="mb-0 mt-2 text-light">Loading...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  data() {
    return {
      isLoading: false,
      stream: {
        url: null,
        isInitialized: false,
      },
      videoPlayer: null,
      isLoadingStream: false,
      lives: [],
    };
  },
  methods: {
    async initializeLiveStream(live) {
      this.stream = {
        ...live,
        isInitialized: true,
      };
      this.isLoadingStream = true;

      await this.loadStream();

      this.isLoadingStream = false;
    },
    async loadStream() {
      if (this.stream.url) {
        if (this.videoPlayer != null) {
          this.videoPlayer.src({
            type: "application/x-mpegURL",
            src: this.stream.url,
          });
        } else {
          let videoElement = document.getElementById("liveStream");

          this.videoPlayer = videojs(videoElement, {
            controls: true,
            autoplay: true,
            preload: "auto",
            fill: true,
          });

          this.videoPlayer.src({
            type: "application/x-mpegURL",
            src: this.stream.url,
          });
        }

        return;
      }
    },
  },
  async mounted() {
    const [call, err] = await this.Helper.handle(this.API.get("lives"));

    this.lives = call.data.map((x) => {
      return {
        id: x.id,
        name: x.name,
        url: x.streamUrl,
        isInitialized: false,
      };
    });
  },
};
</script>

<style scoped>
.video-frame {
  position: relative;
  background-color: #1b252b;
}

.bg-img {
  background-image: url("../assets/images/infrasel-logo-white.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.video-thumbnail {
  position: relative;
  display: block;
  background-color: #1b252b;
}
</style>